import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/1.jpg";
import i2 from "./assets/images/2.jpg";
import i3 from "./assets/images/3.jpg";
import i4 from "./assets/images/4.jpg";
import i5 from "./assets/images/5.jpg";
import igif from "./assets/images/Catmint Cartel.gif";
import i6 from "./assets/images/6.jpg";
import i7 from "./assets/images/7.jpg";
import i8 from "./assets/images/8.jpg";
import i9 from "./assets/images/9.jpg";
import NFTCalImgSrc from "./assets/images/nft_calendar_logo_vector_optimized.svg";
import Slider  from 'rc-slider';
import 'rc-slider/assets/index.css';

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #a070dd;
  padding: 10px;
  font-weight: bold;
  color: #ffffff;
  width: 300px;
  cursor: pointer;
  box-shadow: 2px 8px 4px -2px rgba(250, 250, 0, 0.5);
  -webkit-box-shadow: 2px 3px 10px -2px rgba(250, 250, 0, 0.5);
  -moz-box-shadow: 2px 8px 4px -2px rgba(250, 250, 0, 0.5);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    -webkit-box-shadow: 2px 3px 40px -2px rgba(250, 250, 0, 0.9);
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  max-width: 1750px;
  @media (min-width: 767px) {
    flex-direction: column;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  flex-basis: 33.3%
  transition: width 0.5s;
  transition: height 0.5s;
  margin: 22px;
  border-radius: 20px;
  @media (max-width: 767px) {
    width: 300px;
    height: 300px;
  }
`;

export const StyledTitle = styled.h1`
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
  font-family: sans-serif;
  font-size: 5.6rem;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: -4px 4px #ef3550, -8px 8px #f48fb1, -12px 12px #7e57c2, -16px 16px #2196f3, -20px 20px #26c6da, -24px 24px #43a047, -28px 28px #eeff41, -32px 32px #f9a825, -36px 36px #ff5722;
  @media (max-width: 767px) {
    font-size: 40px;
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [currentAmount, setCurrentAmount] = useState(1);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    var gasLimitPrice = (_amount == 1) ? 160000 : 285000;
    setFeedback("Minting Cat");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: gasLimitPrice,
        //to: "",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((.02 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("It seems the transaction was cancelled.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Visit Opensea.io to view your generated NFT!"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const onSliderChange = value => {
    setCurrentAmount(value);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "var(--black)" }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 12 }}>
        <s.HeaderContainer flex={1} fd={"row"}>
          <s.Container flex={1} jc={"flex-end"} ai={"flex-start"} fd={"row"} style={{ color: "white", textDecoration: "none"}}>
            <a href="https://twitter.com/CatmintCartel" style={{ color: "white", textDecoration: "none", fontSize: 30, paddingRight: 10, fontWeight: "bold"}}>Twitter </a>
            <a href="https://discord.gg/35GCj6qRPs" style={{ color: "white", textDecoration: "none", fontSize: 30, paddingRight: 10, fontWeight: "bold"}}>Discord</a>
            <a href="https://opensea.io/collection/the-catmint-cartel" style={{ color: "white", textDecoration: "none", fontSize: 30, paddingRight: 10, fontWeight: "bold"}}> Opensea</a>
          </s.Container>
        </s.HeaderContainer>
        <ResponsiveWrapper flex={1} style={{ padding: 12 }}>
          <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"}>
            <s.ContainerImageMobile flex={1} jc={"center"} ai={"center"} fd={"row"}>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i5}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i2}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i3}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i4}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={igif}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i6}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i7}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i8}/>
              </s.ImgContainer>
              <s.ImgContainer>
                <StyledImg alt={"The Catmint Cartel"} src={i9}/>
              </s.ImgContainer>
            </s.ContainerImageMobile>
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{ backgroundColor: "#000000", padding: 12 }}
            >
              <StyledTitle style={{ textAlign: "center",marginRight:"-40px", fontWeight: "bold" }}>
                THE CATMINT CARTEL
              </StyledTitle>
              <s.TextDescription style={{ textAlign: "center", fontWeight: "bold",fontSize: 25, width: "90%", lineHeight: 1 }}>
                <s.SpacerMedium />
                <p>Each stylish Cat of this 1024 Catmint Cartel is out-and-outer, unrepeatable, and absolutely meaowish! All generative art kitties live in Ethereum blockchain and wait for you to take them home.</p>
                <br/>
                <p>Each Cat is one-of-a-kind and 100% owned by you; it cannot be replicated, taken away, or destroyed.</p>
              </s.TextDescription>
              <s.SpacerSmall/>
              <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"} style={{ color: "white", textDecoration: "none"}}>
                <a href="https://nftcalendar.io/event/the-catmint-cartel-nft-drop/" style={{ color: "white", textDecoration: "none", flex: 1, justifyContent: "center", alignItems: "center", flexDirection:"row", display: "flex", fontSize: 20}}>
                  <span>AS SEEN ON </span>
                  <img height="50" src={NFTCalImgSrc} alt=""/>
                </a>
              </s.Container>
              <s.SpacerSmall />
              <s.TextTitle
                  style={{ textAlign: "center", fontSize: 26, fontWeight: "bold" }}
              >
                {(data.totalSupply) ? data.totalSupply : 17}/1024 Cat NFTs minted | Price 0.02 Ξ
              </s.TextTitle>
              <s.SpacerSmall />
              {Number(data.totalSupply) === 1024 ? (
                <>
                  <s.SpacerSmall />
                  <s.TextDescription style={{ textAlign: "center" }}>
                    You can still buy and trade Catmint Cartel NFTs on{" "}
                    <a
                      target={""}
                      href={"https://opensea.io/collection/the-catmint-cartel"}
                    >
                      Opensea.io
                    </a>
                  </s.TextDescription>
                </>
              ) : (
                <>
                  <Slider style={{ textAlign: "center", width: "90%", flex: 1, justifyContent: "center" }} min={1}
                          max={20}
                          trackStyle={{ backgroundColor: '#a070dd', height: 10 }}
                          handleStyle={{
                            borderColor: 'pink',
                            height: 28,
                            width: 28,
                            marginLeft: 0,
                            marginTop: -9,
                            backgroundColor: 'white',
                          }}
                          railStyle={{ backgroundColor: '#a070dd', height: 10 }}
                          defaultValue={1}
                          value={currentAmount}
                          onChange={onSliderChange}
                  />
                  <s.SpacerSmall />
                  <s.TextTitle>
                    Mint {currentAmount} Cat NFTs ({currentAmount * 0.02}Ξ)
                  </s.TextTitle>
                  <s.TextTitle style={{ textAlign: "center" }}>
                  </s.TextTitle>
                  <s.TextDescription style={{ textAlign: "center" }}>
                    Max Cats Per Transaction: 20
                  </s.TextDescription>
                  <s.SpacerMedium />
                  {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription style={{ textAlign: "center" }}>

                      </s.TextDescription>
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        🦊 CONNECT WALLET
                      </StyledButton>
                      <s.TextDescription style={{ textAlign: "center" }}>
                        {feedback}
                      </s.TextDescription>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription style={{ textAlign: "center" }}>
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs(currentAmount);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy..." : "Buy "+ currentAmount +" NFT"}
                      </StyledButton>
                    </s.Container>
                  )}
                </>
              )}
            </s.Container>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 12 }}>
          <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"}>
            <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
              <s.SpacerSmall />
              <StyledTitle style={{fontSize: 40}}>
               ROADMAP
              </StyledTitle>
              <s.TextDescription style={{ textAlign: "left", fontSize: 30, fontWeight: "bold" }}>
                <ul>
                  <li>10% - First giveaways for holders;</li>
                  <li>25% - Cats are airdropped to community;</li>
                  <li>50% - Added more 512 Catmint Cartel to mint;</li>
                  <li>75% - Airdropped 1/1 interactive NFTs for holders;</li>
                  <li>100% - The Catmint Cartel Interactive Collection Release;</li>
                </ul>
              </s.TextDescription>
            </s.Container>
            <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
              <s.SpacerSmall />
              <StyledTitle style={{fontSize: 40}}>
                About
              </StyledTitle>
              <s.TextDescription style={{ textAlign: "left", fontSize: 30, fontWeight: "bold" }}>
                The Catmint Cartel is a collection of 1024 kitties living on the Ethereum blockchain and waiting for you to take them home.
                These Сats are composed of a variety of traits randomly assigned together and generated through a code written using Python language.
                The first 17 NFTs was reserved for the community and will be delivered according to a ROADMAP;
              </s.TextDescription>
            </s.Container>
          </s.Container>
        </ResponsiveWrapper>
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", fontSize: 14 }}>
            Inspired by WeirdWhales and TheDudes
          </s.TextDescription>
        </s.Container>
        <s.Container jc={"center"} ai={"center"}>
          <s.TextDescription style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}>
            <span>Contract | </span>
            <a style={{color: '#a070dd'}} href="https://etherscan.io/address/0x9a9e5e16417e870b9cee84b235d7f4d0aef22455">0x9a9e5e16417e870b9cee84b235d7f4d0aef22455</a>
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
